<template>
  <section
    id="main-hero"
    :style="{'background-image': `url(${heroBackground})`}"
    class="main-hero section is-small"
  >
    <div class="container" :class="[fluid ? 'is-fluid px-0' : 'is-fullhd']">
      <template v-if="isTitleObject">
        <h1
          class="title has-text-white has-text-centered has-text-weight-bold
            is-hidden-touch"
        >
          {{ title.desktop }}
        </h1>
        <h1
          class="title has-text-white has-text-centered has-text-weight-bold
            is-hidden-desktop"
        >
          {{ title.touch }}
        </h1>
      </template>
      <template v-else>
        <h1
          class="title has-text-white has-text-centered has-text-weight-bold"
        >
          {{ title }}
        </h1>
      </template>
      <template v-if="subtitle && isSubtitleObject">
        <h2
          v-if="subtitle"
          class="subtitle has-text-white has-text-centered is-hidden-touch"
        >
          {{ subtitle.desktop }}
        </h2>
        <h2
          v-if="subtitle"
          class="subtitle has-text-white has-text-centered is-hidden-desktop"
        >
          {{ subtitle.touch }}
        </h2>
      </template>
      <template v-else-if="subtitle">
        <h2
          v-if="subtitle"
          class="subtitle has-text-white has-text-centered"
        >
          {{ subtitle }}
        </h2>
      </template>
      <div id="main-hero-container" class="columns">
        <div class="column is-hidden-touch">
          <slot name="points">
            <top-points :title="sideTitle || title" :boxes="sideBoxes" :survey-title="surveyTitle" />
          </slot>
        </div>
        <div class="column" :class="[fluid ? 'is-8-desktop' : 'is-7-desktop']">
          <breadcrumb
            v-if="breadcrumb"
            :path="breadcrumb"
          />
          <slot>
            <lazy-hydrate when-idle>
              <loan-application />
            </lazy-hydrate>
          </slot>
        </div>
        <div class="column is-hidden-touch" style="position: relative;">
          <img
            v-if="showTheGirl"
            v-lazy="standingPerson"
            alt="Rahalaitos"
            style="position: absolute;left: -1rem;bottom: -0.75rem;height: 43rem;max-width: none;"
          >
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import _ from 'lodash'
import LazyHydrate from 'vue-lazy-hydration'

export default {
  components: {
    breadcrumb: () => import( '~/components/breadcrumb' ),
    LazyHydrate,
    loanApplication: () => import( '~/components/loanApplication' ),
    topPoints: () => import( '~/components/layout/mainhero/topPoints' )
  },
  props: {
    /**
     * Hero title
     * - String: Same text on mobile and touch
     * - Object: Support key's `desktop`, `touch` for seperate text's
     */
    title: {
      type: [String, Object],
      required: false,
      default: ''
    },
    /**
     * Hero subtitle
     * - String: Same text on mobile and touch
     * - Object: Support key's `desktop`, `touch` for seperate text's
     */
    subtitle: {
      type: [String, Object],
      required: false,
      default: ''
    },
    sideTitle: {
      type: String,
      required: false,
      default: ''
    },
    sideBoxes: {
      type: Array,
      required: false,
      default: undefined
    },
    heroBackground: {
      type: String,
      required: false,
      default: require( '~/assets/img/hero-bg.jpg' )
    },
    showTheGirl: {
      type: Boolean,
      required: false,
      default: true
    },
    standingPerson: {
      type: String,
      required: false,
      default: require( '~/assets/img/kata/standing-2022.png' )
    },
    surveyTitle: {
      type: String,
      default: undefined
    },
    fluid: {
      type: Boolean,
      default: false
    },
    breadcrumb: {
      type: Object,
      required: false,
      default: () => {}
    }
  },
  computed: {
    isTitleObject () {
      return _.isPlainObject( this.title )
    },
    isSubtitleObject () {
      return _.isPlainObject( this.subtitle )
    }
  }
}
</script>

<style lang="scss">
#main-hero {
  >.container>.title {
      text-shadow: 1px 1px 1px #2a3c4a;
    & + .subtitle {
      text-shadow: 1px 1px 1px #2a3c4a;
    }
  }
}
</style>
